import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid,
  Link, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import Loader from "../../../components/Loader";
import { CatalogPageCustomDialog } from "../../../components/Dialog/Dialog";
import { getPriceInFormat, getPurchaseOptionsAsObject } from "../../../utils";
import {IProduct, IPurchaseOptionsAsObject} from "../../../shared/types";

const minQuantity = 1;
const maxIncrementQuantity = 500;
const maxTotalQuantity = 100000000;

const TitleComponent = ({ product, termDuration, billingCycle, retailPrice, isUpdatingSubscription }: any) => {
  // Just format whatever you receive
  const displayPrice = useMemo(() => {
    if (typeof retailPrice === "number") {
      return getPriceInFormat(retailPrice);
    }
    return null;
  }, [retailPrice]);

  const displayDuration = useMemo(() => {
    if (termDuration === "P1Y" && billingCycle === "monthly") {
      return "month";
    }
    return termDuration === "P1M" ? "month" : "year";
  }, [termDuration, billingCycle]);

  return (
      <div className="modal-head">
        <div className="popup-head">
          <h3>{product.title}</h3>
          {!isUpdatingSubscription && displayPrice !== null && (
              <h5>MSRP: ${displayPrice}/{displayDuration}</h5>
          )}
          {!isUpdatingSubscription && displayPrice === null && (
              <h5>MSRP: N/A</h5>
          )}
        </div>
      </div>
  );
};

const BillingTermComponent = ({ termDuration, onUpdateFormData, isUpdatingSubscription, purchaseOptionsAsObj }: any) => {

  const onBillingUpdate = (event: any) => {
    onUpdateFormData("termDuration", event.target.value);
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography className="popup-field-label">Term:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl disabled={isUpdatingSubscription}>
          <ToggleButtonGroup
            disabled={isUpdatingSubscription}
            color="primary"
            exclusive
            value={termDuration}
            onChange={onBillingUpdate}
            aria-label="Platform"
            className="toggle-button"
          >
            { purchaseOptionsAsObj?.P1M && <ToggleButton value="P1M" className="renew-option">Monthly</ToggleButton> }
            { purchaseOptionsAsObj?.P1Y && <ToggleButton value="P1Y" className="renew-option">Yearly</ToggleButton> }
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
    </>
  )
}

const BillingFrequencyComponent = ({ selectedBillingCycle, selectedBillingOptions, onUpdateFormData, isUpdatingSubscription }: any) => {
  const onChangeBillingCycle = (e: any) => {
    onUpdateFormData("billingCycle", e.target.value);
  }
  const labels: any = { monthly: "Monthly", annual: "Annually" };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography className="popup-field-label">Billing Frequency</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth disabled={!selectedBillingOptions.length || isUpdatingSubscription}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="payment-select"
            onChange={onChangeBillingCycle}
            value={selectedBillingCycle}
            disabled={selectedBillingOptions.length <= 1}
          >
            {selectedBillingOptions.map((billingOption: any) => (
              <MenuItem value={billingOption.billing_cycle} key={billingOption.billing_cycle}>{labels[billingOption.billing_cycle]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}

const QuantityComponent = ({ quantity, onUpdateFormData, minQty }: any) => {
  const [rawQuantity, setRawQuantity] = useState(quantity);

  const validateQuantity = (newQuantity: number) => {
    if (isNaN(newQuantity) || newQuantity < minQty) {
      return minQty;
    }
    if (newQuantity > maxTotalQuantity) {
      return maxTotalQuantity;
    }
    if (newQuantity - minQty > maxIncrementQuantity) {
      return minQty + maxIncrementQuantity;
    }
    return newQuantity;
  }

  const onSetQuantity = (newQuantity: number) => {
    setRawQuantity(newQuantity);
    onUpdateFormData("quantity", newQuantity || 0);
  }

  const onDecrementClick = () => {
    const newQuantity = validateQuantity(quantity - 1);
    onSetQuantity(newQuantity);
  }

  const onIncrementClick = () => {
    const newQuantity = validateQuantity(quantity + 1);
    onSetQuantity(newQuantity);
  }

  const onQuantityChange = (e: any) => {
    const newQuantity = validateQuantity(parseInt(e.target.value, 10));
    setRawQuantity(newQuantity);
  }

  const onQuantityBlur = () => {
    let newQuantity = validateQuantity(parseInt(rawQuantity, 10));
    onSetQuantity(newQuantity);
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography className="popup-field-label">Quantity:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="set-quantity">
          <Button onClick={onDecrementClick} className="quantity-minmax quantity-sub">-</Button>
          <FormControl>
            <TextField
              type="number"
              id="outlined-basic"
              className="quantity-select"
              inputProps={{ min: minQuantity, max: maxTotalQuantity }}
              value={rawQuantity}
              onChange={onQuantityChange}
              onBlur={onQuantityBlur}
            />
          </FormControl>
          <Button onClick={onIncrementClick} className="quantity-minmax quantity-add">+</Button>
        </div>
      </Grid>
    </>
  )
}

const YourPriceComponent = ({ totalPrice, termDuration, billingCycle }: any) => {
  const displayPrice = useMemo(() => {
    if (typeof totalPrice === "number") {
      return getPriceInFormat(totalPrice);
    }
    return null;
  }, [totalPrice]);

  const displayDuration = useMemo(() => {
    if (termDuration === "P1Y" && billingCycle === "monthly") {
      return "month";
    }
    return termDuration === "P1M" ? "month" : "year";
  }, [termDuration, billingCycle]);

  return (
      <Typography className="price-per-month">
        {displayPrice !== null
            ? `Your price: $${displayPrice}/${displayDuration}`
            : "Your price: N/A"}
      </Typography>
  );
};

const TermsAndConditionComponent = ({ agreeTermAndCondition, onUpdateFormData, product }: any) => {
  const onAgreeTermsClick = (e: any) => {
    onUpdateFormData("agreeTermAndCondition", e.target.checked);
  };

  return (
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
              checked={agreeTermAndCondition}
              control={<Checkbox onClick={onAgreeTermsClick} />}
              label={
                <Typography variant="body2">
                  I agree to the{" "}
                  <Link
                      target="_blank"
                      href="/terms-and-conditions"
                      underline="none"
                      color="#333"
                  >
                    <b> Terms &amp; Conditions</b>
                  </Link>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>*</Typography>
                </Typography>
              }
              className="checkbox"
          />
        </FormGroup>
        <Typography variant="caption">
          [*] The prices shown have commitment requirements based on the term selected when purchasing. Monthly terms have a monthly commitment, and yearly terms have a yearly commitment, regardless of the billing frequency.
        </Typography>
      </Grid>
  );
};

type PropType = {
  product: IProduct;
  onClose: Function;
  onSave: any;
  isSaving: any;
  isUpdatingSubscription?: boolean;
  defaultFormState?: { termDuration: string, quantity: number, billingCycle: string, agreeTermAndCondition?: boolean };
}

const formStateDefault:PropType["defaultFormState"] = {
  quantity: 1,
  termDuration: "", // P1M or P1Y
  billingCycle: "", // monthly or yearly
  agreeTermAndCondition: false
};
const BuySubscriptionModal = (props: PropType) => {
  const {
    product, onClose, onSave, isSaving, isUpdatingSubscription, defaultFormState = formStateDefault
  } = props;
  const purchaseOptionsAsObj = useMemo(() => !isUpdatingSubscription ? getPurchaseOptionsAsObject(product.purchase_options): {[defaultFormState.termDuration]: {}} as IPurchaseOptionsAsObject, [product.purchase_options, isUpdatingSubscription, defaultFormState.termDuration]);

  const [selectedBillingOptions, setSelectedBillingOptions] = useState<IProduct["purchase_options"]>([]);
  const [formData, setFormData] = useState(defaultFormState);

  const onUpdateFormData = useCallback((key: string, value: any) => {
    setFormData((prevData: any) => ({ ...prevData, [key]: value }));
  }, []);

  const onSubmitClick = () => {
    const { product_id, sku_id } = product;
    const data = {
      product_id,
      sku_id,
      quantity: formData.quantity,
      term_duration: formData.termDuration,
      billing_plan: formData.billingCycle,
    }
    onSave(data);
  }

  const calculatedPrice = useMemo(() => {
    if (isUpdatingSubscription) {
      return { salePrice: null, retailPrice: null };
    }

    const { billingCycle, termDuration, quantity } = formData;

    let salePrice = 0;
    let retailPrice = 0;

    // If P1M
    if (termDuration === "P1M" && purchaseOptionsAsObj.P1M?.monthly) {
      salePrice = (purchaseOptionsAsObj.P1M.monthly.sale_price || 0) * quantity;
      retailPrice = (purchaseOptionsAsObj.P1M.monthly.retail_price || 0) * quantity;

      // If P1Y
    } else if (termDuration === "P1Y" && purchaseOptionsAsObj.P1Y) {
      if (billingCycle === "monthly" && purchaseOptionsAsObj.P1Y.monthly) {
        salePrice =
            (purchaseOptionsAsObj.P1Y.monthly.sale_price || 0) * quantity;
        retailPrice =
            (purchaseOptionsAsObj.P1Y.monthly.retail_price || 0) * quantity;
      } else if (billingCycle === "annual" && purchaseOptionsAsObj.P1Y.annual) {
        salePrice =
            (purchaseOptionsAsObj.P1Y.annual.sale_price || 0) * quantity;
        retailPrice =
            (purchaseOptionsAsObj.P1Y.annual.retail_price || 0) * quantity;
      }
    }

    return { salePrice, retailPrice };
  }, [formData, purchaseOptionsAsObj, isUpdatingSubscription]);

  const isAllowSubmit = isSaving || (isUpdatingSubscription ?
    !Boolean((formData.quantity > defaultFormState.quantity) && formData.agreeTermAndCondition):
    !Boolean(formData.termDuration && formData.billingCycle && (formData.quantity > 0) && formData.agreeTermAndCondition)
  )

  useEffect(() => {
    if (!isUpdatingSubscription) {
      if (purchaseOptionsAsObj?.P1M) onUpdateFormData("termDuration", "P1M");
      else if (purchaseOptionsAsObj?.P1Y) onUpdateFormData("termDuration", "P1Y");
    } else {
      onUpdateFormData("termDuration", defaultFormState.termDuration);
    }
  }, [purchaseOptionsAsObj, onUpdateFormData, isUpdatingSubscription, defaultFormState.termDuration]);

  useEffect(() => {
    if (formData.termDuration) {
      if (product.purchase_options && Array.isArray(product.purchase_options) && product.purchase_options.length > 0) {
        // Filter the purchase options based on termDuration
        const billingOptions = product.purchase_options.filter(
            (po) => po.term_duration === formData.termDuration
        );
        setSelectedBillingOptions(billingOptions);

        // Only update the billing cycle if the current value is not valid
        if (billingOptions.length) {
          const currentOption = billingOptions.find(
              (option) => option.billing_cycle === formData.billingCycle
          );
          if (!currentOption) {
            onUpdateFormData("billingCycle", billingOptions[0].billing_cycle);
          }
        }
      } else {
        // When purchase_options are missing (i.e. for subscription renewals), fallback to the existing values
        setSelectedBillingOptions([{
          billing_cycle: formData.billingCycle,
          retail_price: NaN,
          sale_price: NaN,
          term_duration: formData.termDuration
        }]);
      }
    }
  }, [product.purchase_options, formData.termDuration, onUpdateFormData, formData.billingCycle]);

  return (
    <CatalogPageCustomDialog onClose={onClose}>
      <div>
        <form>
          <TitleComponent
            product={product}
            termDuration={formData.termDuration}
            billingCycle={formData.billingCycle}
            retailPrice={calculatedPrice.retailPrice}
            isUpdatingSubscription={isUpdatingSubscription}
          />
          <div className="popup-main">
            <Grid container spacing={2}>
              <BillingTermComponent
                isUpdatingSubscription={isUpdatingSubscription}
                termDuration={formData.termDuration}
                onUpdateFormData={onUpdateFormData}
                purchaseOptionsAsObj={purchaseOptionsAsObj}
              />
              <BillingFrequencyComponent
                isUpdatingSubscription={isUpdatingSubscription}
                selectedBillingOptions={selectedBillingOptions}
                selectedBillingCycle={formData.billingCycle}
                onUpdateFormData={onUpdateFormData}
              />
              <QuantityComponent
                minQty={!isUpdatingSubscription ? minQuantity: defaultFormState.quantity}
                quantity={formData.quantity}
                onUpdateFormData={onUpdateFormData}
              />
            </Grid>
          </div>
          { !isUpdatingSubscription && <YourPriceComponent totalPrice={calculatedPrice.salePrice} termDuration={formData.termDuration} billingCycle={formData.billingCycle} /> }
          <div className="modal-item">
            <Grid container spacing={3}>
              <TermsAndConditionComponent
                  agreeTermAndCondition={formData.agreeTermAndCondition}
                  onUpdateFormData={onUpdateFormData}
                  product={product}
              />
              {/* Submit Button */}
              <Grid item xs={12} md={5}>
                <Button
                  id="add-btn"
                  className="submit-btn"
                  disabled={isAllowSubmit}
                  onClick={onSubmitClick}
                >
                  {isSaving ? (<Box mr={2}><Loader /></Box>) : ("")}&nbsp;Add</Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </CatalogPageCustomDialog>
  );
};

export default BuySubscriptionModal;
